<template>
  <div class="w-full h-screen"></div>
</template>

<script>
export default {
  name: "BankidVerified",
  mounted() {
    this.$store
      .dispatch("auth/verifyBankid")
      .then(() => {
        this.$router.push({ name: "pm-select-forms" }).catch(error => {
          console.log("error", error);
        });
      })
      .catch(() => {
        this.$router.push({ name: "pm-login" }).catch(error => {
          console.log("error", error);
        });
      });
  },
};
</script>
