<template>
  <div>
    <div class="mb-2">
      <transition name="fade">
        <error-message v-if="error" :message="$t(error)" />
      </transition>
    </div>

    <form action="#" method="POST">
      <div>
        <label class="block text-sm font-medium leading-5 text-gray-700">{{ $t("login.bankidlogin") }}</label>
        <div class="mt-1 rounded-md shadow-sm">
          <input
            v-model.trim="ssn"
            id="ssn"
            type="text"
            @change="ssnValidated"
            placeholder="YYYYMMDDXXXX"
            required
            class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div class="mt-6">
        <span class="block w-full rounded-md shadow-sm">
          <button
            @click.prevent="login"
            :disabled="!validSsn"
            :class="{ 'bg-indigo-600': validSsn }"
            type="submit"
            class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-200 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring ring-indigo-200 active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            {{ $t("login.login") }}
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { SwedishSsnValidator } from "@/utils/validators/ssnvalidator.js";

export default {
  name: "pm",
  data() {
    return {
      validSsn: false,
    };
  },
  mounted() {
    if (this.pmLoggedIn) {
      this.$router.replace({ path: "/pm/select-forms" });
    }
  },
  computed: {
    ...mapState("pm", {
      pmLoggedIn: state => state.pmLoggedIn,
    }),
    ...mapState("auth", {
      error: state => state.errors.pm,
    }),
    ...mapFields("auth", ["ssn"]),
  },
  created() {
    delete this.$http.defaults.headers.common["Authorization"];
  },
  methods: {
    login() {
      this.$store
        .dispatch("auth/loginBankid")
        .then(redirectUrl => {
          window.location = redirectUrl;
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    async ssnValidated() {
      this.validSsn = false;
      const regex = /\s|-|\+/gi;

      const value = this.ssn.replace(regex, "");

      this.validSsn = value.length === 12 && ["19", "20"].indexOf(value.substr(0, 2)) != -1 ? SwedishSsnValidator(value.substr(2)) : false;
    },
  },
};
</script>
