<template>
  <div class="bg-white shadow sm:rounded-lg px-4">
    <form @submit.prevent="registerForms">
      <div class="py-4 px-2">
        <h1>{{ $t("pm.header") }}</h1>
        <div v-for="(form, key) in forms" :key="key">
          <label :for="form.name">
            <input :id="form.name" type="checkbox" v-model="checkedForms" :value="form.name" />
            {{ form.translation }}
          </label>
        </div>
      </div>
      <div class="flex justify-between py-4">
        <cm-button type="submit" :disabled="checkedForms.length < 1" class="z-20 text-blue-400 p-2">
          {{ $t("button.continue") }}
        </cm-button>
        <cm-button type="button" @click="logout">
          {{ $t("button.logout") }}
        </cm-button>
      </div>
    </form>

    <form method="post" id="loginform" ref="pmSubmit" :action="pmdata.url">
      <input type="hidden" name="parameters" :value="pmdata" />
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SelectForms",
  data() {
    return {
      checkedForms: [],
      pmData: null,
    };
  },
  computed: {
    ...mapState("pm", {
      forms: state => state.pmForms,
      pmdata: state => state.pmData,
    }),
  },
  methods: {
    async registerForms() {
      this.pmdata.modules = this.checkedForms;
      this.pmdata.width = window.screen.availWidth;
      this.pmdata.height = window.screen.availHeight;
      this.pmdata.redirect = window.location.origin + "/pm";
      this.$refs.pmSubmit.parameters.value = JSON.stringify(this.pmdata);

      this.$refs.pmSubmit.submit();
      this.$store.dispatch("pm/logout");
    },
    logout() {
      this.$store.dispatch("pm/logout").then(() => {
        this.$router.push({ path: "/pm" });
      });
    },
  },
};
</script>